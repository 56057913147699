"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Dotdotdot = void 0;
const ng = window.angular;
function Dotdotdot($compile, $timeout) {
    return {
        restrict: 'A',
        scope: {
            options: '=ngDotdotdot',
            value: '=ngDotdotdotValue'
        },
        //@ts-ignore
        link: ($scope, $element) => {
            const originalInnerHtml = $compile(`<a>${$element[0].innerHTML}</a>`)($scope.$parent);
            $scope.$watch('value', () => {
                $element.css("display", "none");
                $scope.showFull = () => {
                    $element.empty();
                    $element.append(originalInnerHtml);
                };
                $timeout(() => {
                    const originalContent = originalInnerHtml.html();
                    let shortContent = undefined;
                    if ($scope.options.type == 'fract') {
                        const parts = originalContent.split(/[\.\,]/);
                        if (parts.length > 1) {
                            if (parts[parts.length - 1].length > $scope.options.size) {
                                parts[parts.length - 1] = parts[parts.length - 1].slice(0, $scope.options.size);
                                shortContent = parts.join(',');
                                $element.empty();
                                $element.append($compile(ng.element(`<span>${shortContent}<a ng-click="showFull()" href>&mldr;</a></span>`))($scope));
                                $element.css("display", "unset");
                                return;
                            }
                        }
                    }
                    else if (($scope.options.type == 'text') && (originalContent.length > $scope.options.size)) {
                        shortContent = originalContent.slice(0, $scope.options.size);
                        $element.empty();
                        $element.append($compile(ng.element(`<span>${shortContent}<a ng-click="showFull()" href>&mldr;</a></span>`))($scope));
                        $element.css("display", "unset");
                        return;
                    }
                    $scope.showFull();
                    $element.css("display", "unset");
                });
            });
        },
    };
}
exports.Dotdotdot = Dotdotdot;
Dotdotdot.$inject = ['$compile', '$timeout'];
