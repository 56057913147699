"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ng = window.angular;
const action_1 = require("@src/app/services/action");
const ngDotdotdot_1 = require("@src/app/directives/ngDotdotdot");
const parseFractionInt = (value, base = 2) => {
    var res = value.split('.');
    return res.length < 2 ?
        parseInt(value, base) :
        parseInt(res[0] + res[1], base) / Math.pow(base, res[1].length);
};
class BinarycalculatorCtrl {
    constructor($scope, $timeout, $filter, actionService) {
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.$filter = $filter;
        this.actionService = actionService;
        this.inputBinPattern = '\\s*-?[01\\s]+(?:\\.[01\\s]+)?';
        this.kind = 'calculator';
        this.calculatorActions = [
            {
                key: 'add',
                title: this.$filter('translate')('Addition'),
                symbol: 'plus',
            },
            {
                key: 'sub',
                title: this.$filter('translate')('Subtraction'),
                symbol: 'minus',
            },
            {
                key: 'mul',
                title: this.$filter('translate')('Multiplication'),
                symbol: 'xmark',
            },
            {
                key: 'div',
                title: this.$filter('translate')('Division'),
                symbol: 'divide',
            },
        ];
        this.calculatorAction = this.calculatorActions[0];
        this.converterTargets = [
            {
                key: '2to10',
                title: this.$filter('translate')('Binary to decimal'),
                pattern: this.inputBinPattern,
                resultPrefix: this.$filter('translate')('Decimal'),
                type: 'text'
            },
            {
                key: '10to2',
                title: this.$filter('translate')('Decimal to binary'),
                resultPrefix: this.$filter('translate')('Binary'),
                type: 'number'
            },
            {
                key: '2to16',
                title: this.$filter('translate')('Binary to hexadecimal'),
                pattern: this.inputBinPattern,
                resultPrefix: this.$filter('translate')('Hexadecimal'),
                type: 'text'
            },
            {
                key: '16to2',
                title: this.$filter('translate')('Hexadecimal to binary'),
                pattern: '\\s*\\-?[0-9xXA-Fa-f\\s]+(?:\\.[0-9xXA-Fa-f\\s]+)?',
                resultPrefix: this.$filter('translate')('Binary'),
                type: 'text'
            },
            {
                key: '2to8',
                title: this.$filter('translate')('Binary to octal'),
                resultPrefix: this.$filter('translate')('Octal'),
                pattern: this.inputBinPattern,
                type: 'text'
            },
            {
                key: '8to2',
                title: this.$filter('translate')('Octal to binary'),
                resultPrefix: this.$filter('translate')('Binary'),
                pattern: '\\s*\\-?[0-7\\s]+(?:\\.[0-7\\s]+)?',
                type: 'text'
            },
        ];
        this.converterTarget = this.converterTargets[0];
        this.data = {
            calculator: {
                a: undefined,
                b: undefined,
            },
            converter: {
                c: undefined
            }
        };
        // this.$scope.$watch('$ctrl.data', (data: any) => {
        //     localStorage.setItem(`${this.constructor.name}_data`, JSON.stringify(data))
        // }, true)
        //
        // this.$scope.$watch('$ctrl.calculatorAction', (calculatorAction: any) => {
        //     localStorage.setItem(`${this.constructor.name}_calculatorAction`, JSON.stringify(calculatorAction))
        // })
        //
        // const calculatorAction = JSON.parse(localStorage.getItem(`${this.constructor.name}_calculatorAction`) || 'null')
        // if (calculatorAction) {
        //     this.calculatorAction = calculatorAction
        // }
        //
        // const data = JSON.parse(localStorage.getItem(`${this.constructor.name}_data`) || 'null')
        // if (data) {
        //     this.data = data
        // }
    }
    $onInit() {
    }
    setKind(kind) {
        this.kind = kind;
        this.result = undefined;
        this.error = undefined;
    }
    setCalculatorAction(action) {
        this.calculatorAction = action;
        this.result = undefined;
        this.error = undefined;
    }
    setConverterTarget(target) {
        this.converterTarget = target;
        this.result = undefined;
        this.error = undefined;
    }
    // static limitFract(value: string, size = 10): string {
    //     const p = value.split('.', 2)
    //     let d = p[0]
    //     let f = p[1]
    //
    //     if (f && parseInt(f) > 0) {
    //         if (f.length > size) {
    //             let ff = parseFloat(`0.${f}`).toFixed(size)
    //             while (ff[ff.length - 1] == '0') {
    //                 ff = parseFloat(`0.${f}`).toFixed(size++)
    //             }
    //             f = ff.slice(2, ff.length)
    //         }
    //         return `${d}.${f}`
    //     }
    //
    //     return d
    // }
    submit() {
        if (this.binaryForm && this.binaryForm.$valid) {
            this.error = undefined;
            if (this.kind == 'converter') {
                if (this.data.converter.c != undefined) {
                    try {
                        let c;
                        switch (this.converterTarget.key) {
                            case '2to10':
                                c = parseFractionInt(this.data.converter.c, 2);
                                this.result = c.toString();
                                break;
                            case '10to2':
                                //@ts-ignore
                                this.result = parseFloat(this.data.converter.c).toString(2);
                                break;
                            case '2to16':
                                c = parseFractionInt(this.data.converter.c, 2);
                                this.result = c.toString(16).toUpperCase();
                                break;
                            case '16to2':
                                c = parseFractionInt(this.data.converter.c, 16);
                                this.result = c.toString(2);
                                break;
                            case '2to8':
                                c = parseFractionInt(this.data.converter.c, 2);
                                this.result = c.toString(8);
                                break;
                            case '8to2':
                                c = parseFractionInt(this.data.converter.c, 8);
                                this.result = c.toString(2);
                                break;
                            default:
                                break;
                        }
                    }
                    catch (e) {
                        console.error(e);
                        this.error = this.$filter('translate')('Invalid value');
                    }
                }
            }
            if (this.kind == 'calculator') {
                try {
                    if ((this.data.calculator.a != undefined) && (this.data.calculator.b != undefined)) {
                        const a = parseFractionInt(this.data.calculator.a, 2);
                        const b = parseFractionInt(this.data.calculator.b, 2);
                        if (isNaN(a) || isNaN(b)) {
                            throw "Invalid value";
                        }
                        switch (this.calculatorAction.key) {
                            case 'add':
                                this.result = a + b;
                                break;
                            case 'sub':
                                this.result = a - b;
                                break;
                            case 'mul':
                                this.result = a * b;
                                break;
                            case 'div':
                                this.result = a / b;
                                break;
                            default:
                                break;
                        }
                    }
                }
                catch (e) {
                    console.error(e);
                    this.error = this.$filter('translate')('Invalid value');
                    this.result = undefined;
                }
            }
        }
    }
    copy($event) {
        if (this.result)
            this.actionService.copyFactory($event.target, this.result.value);
    }
    copyCalc($event) {
        if (this.result)
            this.actionService.copyFactory($event.target, undefined);
    }
    clear() {
        if (this.binaryForm) {
            this.binaryForm.$setPristine();
            this.binaryForm.$setUntouched();
        }
        this.result = undefined;
        this.error = undefined;
        this.data.converter.c = undefined;
        this.data.calculator.a = undefined;
        this.data.calculator.b = undefined;
    }
}
BinarycalculatorCtrl.$inject = ['$scope', '$timeout', '$filter', 'ActionService'];
const appModule = ng.module('app');
appModule.service('ActionService', action_1.ActionService);
appModule.directive('ngDotdotdot', ngDotdotdot_1.Dotdotdot);
appModule.filter('decToNs', [() => {
        return (value, ns) => {
            return Number(value).toString(ns).toUpperCase();
        };
    }]);
// appModule.filter('limitFract', ['$filter', ($filter) => {
//     return (value: string, size = 10) => {
//         return value
//     }
// }])
appModule.filter('dotComma', ['$filter', ($filter) => {
        const z = $filter('number')(1.1);
        const dotComma = z[1];
        return (value) => {
            if (ng.isString(value)) {
                if ((value.indexOf('.') > -1) && (dotComma != '.')) {
                    return value.replace('.', dotComma);
                }
            }
            return value;
        };
    }]);
appModule.component('gameBinarycalculator', {
    transclude: true,
    template: require("./game.ng.html"),
    controller: BinarycalculatorCtrl,
    controllerAs: '$ctrl',
    bindings: {
        config: "<"
    }
});
appModule.config(['WsServiceProvider', (WsServiceProvider) => {
        WsServiceProvider.setPrefix('binarycalculator/');
    }]);
